<template lang="pug">
  .failed-email(:class="{'disabled': disabled}" @click="toggle")
    ico-email.pointer
    confirmation-dialog(
      v-model="showing"
      title="Failed email"
      descr="Are you sure you want to re-send Failed exam email?"
      @okBtnClicked="send"
    )
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
import ResultsService from '@/app/admin/modules/results/core/results-service'

export default {
  name: 'ReapplyEmail',

  mixins: [ showingMixin ],

  props: {
    itemID: { type: Number, required: true },
    disabled: { type: Boolean, default: false }
  },

  data: () => ({
    examResultsService: new ResultsService()
  }),

  methods: {
    async send() {
      try {
        await this.examResultsService.sendResultEmail(this.itemID);
        this.$notify({ type: 'success', text: 'Success' });
      } catch (error) {
        this.processError(error, { redirectName: this.$ROUTER_NAMES.LOGIN_CRM })
      }
    }
  },

  components: {
    icoEmail: () => import('@/assets/img/ui/crm/IcoEmail.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>

<style lang="scss" scoped>
.failed-email {
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>